import React from "react"
import Layout from "../components/Layout"

import intro from "./posts/001-intro"

const posts = [intro]//.reverse()

export default function Home() {
  return (
    <Layout>
      {posts.map((P, i) => (
        <div key={i}>
          <P />
        </div>
      ))}
    </Layout>
  )
}

import React from "react";
import Post from "../components/Post";

export default function Intro() {
  return (
    <Post title="introduction" postDate="july 26, 2021">
      <p>
        Welcome to one humble developer! This is a site about developing
        projects, such as websites or games. This is a bit different than most
        tech focused sites out there that try to stay up to date with news, or
        the latest features. This site will contain posts about how to actually
        put all that stuff together and get some work done. It may also contain
        some how to guides that teach code, but that really isn’t the focus
        here.
      </p>
      <p>
        Some topics that will be covered are agile workflows for small teams or
        even individuals, version control workflows for individuals or teams,
        documenting work, and refactoring. After being a professional web
        developer for over twelve years I have found that a big part of
        succeeding is just about knowing how to get work done, and how to
        research when you need more information. I hope to pass on a lot of that
        experience to you all that are reading these posts.
      </p>
      <p>
        If you have suggestions for content, or comments,{" "}
        <a href="https://discord.gg/pyCTjnUhdk">come join me in discord</a> to
        hang out or say hi. Thank you for stopping by today!
      </p>
    </Post>
  );
}
